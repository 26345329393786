<template>
  <div>

    <!-- #page-header -->
    <div id="page-header" class="cover -small" data-image-src="assets/img/bg/kontakt.jpg">
      <div class="container">

        <div class="-wrapper">
          <div class="_inner">

            <h1 v-translate>Súbory cookies</h1>

          </div>
        </div>

      </div>
    </div>
    <!-- /#page-header -->

    <!-- .section -->
    <section class="section">
      <div class="container">

        <div v-translate>

          <p>
          S cieľom zabezpečiť fungovanie tejto webovej stránky ukladáme na vašom zariadení malé dátové súbory, tzv. cookie.
          </p>


          <h3>Čo sú súbory cookie?</h3>

          <p>
          Súbor cookie je malý textový súbor, ktorý ukladá vo vašom počítači alebo mobilnom zariadení web stránka pri jej prehliadaní. Vďaka tomuto súboru si webová stránka na určitý čas uchováva informácie o vašich krokoch a preferenciách (ako sú prihlasovacie meno, jazyk, veľkosť písma a iné nastavenia zobrazovania), takže ich pri ďalšej návšteve stránky nemusíte opätovne všetko nanovo nastavovať.
          </p>

          <h3>Ako používame súbory cookie?</h3>

          <p>
          Web stránka www.webpartner.sk používa súbory cookies na zhromažďovanie anonymných štatistických informácií o návštevníkoch, zapamätanie si používateľských nastavení, pre lepšie prispôsobenie reklám záujmom návštevníkov a pre nevyhnutnú funkcionalitu webstránok. Súbory cookie sa nebudú používať na iný účel ako je uvedený. Udelený súhlas s cookie sa dá jednoducho odobrať. Inštrukcie nájdete nižšie.
          </p>

          <h3>Ako kontrolovať súbory cookie</h3>
          <p>
          Súbory cookie môžete kontrolovať a/alebo zmazať podľa uváženia – podrobnosti si pozrite na stránke aboutcookies.org. Môžete vymazať všetky súbory cookie uložené vo svojom počítači a väčšinu prehliadačov môžete nastaviť tak, aby ste im znemožnili ich ukladanie. V takomto prípade však pravdepodobne budete musieť pri každej návšteve webovej lokality manuálne upravovať niektoré nastavenia a niektoré služby a funkcie nemusia fungovať.
          </p>

          <div class="cookies-table table-responsive">
          <table class="table table-bordered table-striped">
            <tr>
              <th v-translate>Typ</th>
              <th v-translate>Názov</th>
              <th v-translate>Účel</th>
              <th v-translate>Platnosť</th>
              <th v-translate>Omietnutie</th>
            </tr>
            <tr>
              <td v-translate>Analytické cookies</td>
              <td v-translate>Google Analytics</td>
              <td v-translate>Získavanie anonymizovaných štatistických údajov<br> o správaní na stránke</td>
              <td v-translate>2 roky</td>
              <td><span v-translate>Na odmietnutie cookies môžete využiť<br> tento</span>&nbsp;<a target="_blank" href="https://tools.google.com/dlpage/gaoptout" v-translate>doplnok prehliadača</a></td>
            </tr>
          </table>
          </div>



        </div>


      </div>
    </section>
    <!-- /.section -->


  </div>
</template>

<script>
export default {
  name: 'terms',
    metaInfo () {
        return {
            title: this.$gettext('cookies_terms_title'),
            titleTemplate: '%s | Webpartner',
            htmlAttrs: {
                lang: 'sk-SK',
                amp: undefined
            },
            link: [
                {rel: 'canonical', href: 'https://webpartner.sk/cookies'}
            ]
        }
    },

}
</script>
